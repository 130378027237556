export const ALL_PRODUCTS_URL = `/v1/product/customer/all/v2`;

export const ALL_CATEGORIES_URL = `/v1/category/all`;

export const PRODUCT_SUGGESTIONS_URL = `/v1/product/customer/getSuggestions`;

export const POPULAR_PRODUCTS_URL = `/v1/product/customer/popular`;

export const USER_DATA_URL = `/v1/user/profile`;

export const VALIDATE_COUPON_URL = `/v1/coupon/validateCoupon`;

export const VALIDATE_REFERRALCODE_URL = `/v1/user/validateReferralCode`;

export const SIGNUP_URL = `/v1/user/signup`;

export const LOGIN_URL = `/v1/user/login`;

export const ADD_ADDRESS_URL = `/v1/address/add`;

export const DELETE_ADDRESS_URL = `/v1/address/delete`;

export const ALL_ADDRESS_URL = `/v1/address/all`;

export const PAYMENT_DETAILS_URL = `/v1/payment/details`;

export const PLACE_ORDER_URL = `/v2/order/new`;

export const ALL_ORDERS_URL = `/v1/allorders`;

export const ORDER_ITEMS_URL = `/v1/orderitems`;

export const RATE_ORDER_URL = `/v1/order/rate`;

export const ORDER_RATING_URL = `/v1/order/rating`;

export const USER_CHECK_EXISTING_URL = `/v1/user/checkexisting`;

export const PASSWORD_UPDATE_URL = `/v1/user/password/update`;

export const ALL_WISHLIST_ITEMS_URL = `/v1/watchlist/all`;

export const ADD_WISHLIST_URL = `/v1/watchlist/add/product`;

export const DELETE_WISHLIST_URL = `/v1/watchlist/delete/product`;

export const ALL_SLIDES_URL = `/v1/slides/images`;

export const CANCEL_ORDER_URL = `/v1/cancelorder/feedback`;

export const PINCODE_WORKING_URL = `/v1/location/isPinWoking`;

export const ALL_DELIVERY_SLOTS_URL = `/v1/deliveryslot/all`;

export const DELIVERY_SLOTS_URL = `/v1/deliveryslot`;

export const CART_ADD_URL = `/v1/cart/save`;

export const CART_UPDATE_URL = `/v1/cart/update`;

export const CART_DELETE_URL = `/v1/cart/delete`;

export const CART_DELETE_ALL_URL = `/v1/cart/delete/all`;

export const CART_DELETE_AND_UPDATE_URL = `/v1/cart/override`;

export const ALL_CART_URL = `/v1/cart/login/all`;

export const ALL_BLOG_URL = `/v1/blog/all`;

export const BLOG_URL = `/v1/blog`;

export const ALL_RECIPES_URL = `/v1/recipe/all`;

export const ALL_RECIPES_ENTRIES_URL = `/v1/recipe/entries/all`;

export const RECIPES_SEARCH_URL = `/v1/recipe/search`;

export const RECIPE_USER_URL = `/v1/recipe/user`;

export const RECIPE_USER_REGISTER_URL = `/v1/recipe/user/register`;

export const ALL_USER_RECIPES_URL = `/v1/recipe/user/recipes`;

export const ALL_USER_LIKED_RECIPES_URL = `/v1/recipe/user/liked`;

export const ALL_RECIPE_USERS_URL = `/v1/recipe/user/all`;

export const RECIPE_CHECK_DUPLICATES_URL = `/v1/recipe/check_duplicates`;

export const RECIPE_ADD_URL = `/v1/recipe/save`;

export const RECIPE_SAVE_NEW_URL = `/v1/recipe/save/new`;

export const RECIPE_UPDATE_URL = `/v1/recipe/update`;

export const RECIPE_DELETE_URL = `/v1/recipe/delete`;

export const RECIPE_DEACTIVATE_URL = `/v1/recipe/deactivate`;

export const SINGLE_RECIPE_URL = `/v1/recipe/id`;

export const SINGLE_RECIPE_CIPHER_URL = `/v1/recipe/cipher`;

export const RECIPE_ITEM_DELETE_URL = `/v1/recipe/item/delete`;

export const RECIPE_IMAGES_UPDATE_URL = `/v1/recipe/update/images`;

export const RECIPE_PRODUCTS_LIST_URL = `/v1/recipe/products`;

export const PRODUCT_UNITS_LIST_URL = `/v1/product/customer/units/all`;

export const PRODUCT_UNITS_LIST_ONLY_URL = `/v1/product/customer/units`;

export const RECIPE_USER_UPDATE_PRIVACY_URL = `/v1/recipe/user/update/privacy`;

export const RECIPE_FORM_CONSTANTS_URL = `/v1/recipe/constants`;

export const RECIPE_UPDATE_STATUS_URL = `/v1/recipe/update/status`;

export const RECIPE_LIKES_URL = `/v1/recipe/likes`;

export const RECIPE_LIKES_UPDATE_URL = `/v1/recipe/update/likes`;

export const RECIPE_POPULAR_URL = `/v1/recipe/popular`;

export const RECIPE_UPDATE_USER_INFO_URL = `/v1/recipe/update/user`;
