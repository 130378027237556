import LocalMall from '@mui/icons-material/LocalMall';
import PersonAdd from '@mui/icons-material/PersonAdd';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import React from 'react';
import { IconContext } from 'react-icons';
import { BiSolidCategory } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import useAuthStore from '../../Store/AuthStore';
import useBottomNavStore from '../../Store/BottomNavStore';
import useCartStore from '../../Store/CartStore';

const BottomNav = () => {
  /*zustand variables*/
  const { isAuth } = useAuthStore();
  const { cart } = useCartStore();
  const { navtab, setNavTab } = useBottomNavStore();

  /*zustand variables*/
  const history = useHistory();

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          background: 'white',
          zIndex: '1000',
          // display: 'flex',
          borderTop: '1px solid lightgrey',
          width: '100%',
          py: 0.5,
        }}
      >
        <BottomNavigation
          showLabels
          value={navtab}
          onChange={(event, newValue) => {
            setNavTab(newValue);
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <BottomNavigationAction
            onClick={() => {
              history.push('/catalogue');
            }}
            label={<span style={{ fontSize: '2.6vw' }}>Shop</span>}
            icon={<LocalMall sx={{ fontSize: '5vw' }} />}
          />
          <BottomNavigationAction
            onClick={() => {
              history.push('/recipes');
            }}
            label={<span style={{ fontSize: '2.6vw' }}>Recipes</span>}
            icon={<span className="material-symbols-outlined">skillet</span>}
          />
          <BottomNavigationAction
            onClick={() => {
              history.push('/categories');
            }}
            label={<span style={{ fontSize: '2.6vw' }}>Categories</span>}
            icon={
              <IconContext.Provider value={{}}>
                <BiSolidCategory size={22} />
              </IconContext.Provider>
            }
          />
          <BottomNavigationAction
            onClick={() => {
              history.push('/cart');
            }}
            label={<span style={{ fontSize: '2.6vw' }}>Cart</span>}
            icon={
              <Badge
                badgeContent={cart?.length}
                color="error"
                sx={{ fontSize: '4vw' }}
              >
                <ShoppingCart sx={{ fontSize: '5vw' }} />
              </Badge>
            }
          />
          {!isAuth && (
            <BottomNavigationAction
              onClick={() => {
                history.push('/login');
              }}
              label={<span style={{ fontSize: '2.6vw' }}>Login</span>}
              icon={<PersonAdd sx={{ fontSize: '5vw' }} />}
            />
          )}
        </BottomNavigation>
      </Box>
    </>
  );
};
export default BottomNav;
