import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import useAuthStore from '../Store/AuthStore';

/* For authentication of user login and redirecting to suitable routes */
function ProtectedRoute({ component: Component, ...rest }) {
  /* zustand vars */
  const isAuth = useAuthStore(state => state.isAuth);
  /* zustand vars */
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuth) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
}
export function HaltedRoute({ component: Component, ...rest }) {
  /* zustand vars */
  const isAuth = useAuthStore(state => state.isAuth);
  /* zustand vars */
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuth) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
}

export default ProtectedRoute;
