import AccountCircleRounded from '@mui/icons-material/AccountCircleRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Explore from '@mui/icons-material/Explore';
import FavoriteRounded from '@mui/icons-material/FavoriteRounded';
import LocalMall from '@mui/icons-material/LocalMall';
import LocalOffer from '@mui/icons-material/LocalOffer';
import LocationOn from '@mui/icons-material/LocationOn';
import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import ShoppingBasketRounded from '@mui/icons-material/ShoppingBasketRounded';
import VpnKey from '@mui/icons-material/VpnKey';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { useState } from 'react';
import { IconContext } from 'react-icons';
import { BiSolidBookHeart, BiSolidCategory } from 'react-icons/bi';
import { FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import useAuthStore from '../../Store/AuthStore';
import useMiscStore from '../../Store/MiscStore';
import useRecipeStore from '../../Store/RecipeStore';
import useUserStore from '../../Store/UserStore';
import './LeftDrawer.css';
import { caesarEncrypt, encodeId } from '../../helpers';

const classes = {
  newTag: {
    padding: '.15rem .6rem',
    backgroundColor: 'var(--tag)',
    color: 'white',
    fontFamily: 'Inter',
    fontSize: '.8rem',
    borderRadius: '20px',
    fontWeight: '600',
    lineHeight: 1.25,
    marginBlock: 'auto',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '.8rem',
  },
};

// LeftDrawer
const LeftDrawer = () => {
  const { openLeftDrawer, setOpenLeftDrawer } = useMiscStore();
  const { isLogin, recipeUser } = useRecipeStore();
  const { isAuth, setAuth } = useAuthStore();
  const { userData } = useUserStore();

  const currRecipeUserId = isLogin ? recipeUser?.id : '';

  const menuList = [
    {
      title: 'Recipes',
      to: '/recipes/home',
      isNew: true,
      icon: <span className="material-symbols-outlined">skillet</span>,
      collapsedItems: [
        {
          title: 'Explore',
          to: '/recipes/all',
          icon: <Explore />,
        },
        /* conditional menu item for logged in users */
        ...(isAuth
          ? [
              {
                title: 'Instructions',
                to: '/recipes/instructions',
                icon: (
                  <IconContext.Provider value={{}}>
                    <FaInfoCircle size={21} />
                  </IconContext.Provider>
                ),
              },
              {
                title: isLogin ? 'My Recipes' : 'Register',
                to: `/recipes/user/${caesarEncrypt(
                  encodeId(currRecipeUserId),
                )}`,
                icon: isLogin ? (
                  <IconContext.Provider value={{}}>
                    <BiSolidBookHeart size={22} />
                  </IconContext.Provider>
                ) : (
                  <VpnKey />
                ),
              },
            ].map(i => i)
          : []),
      ],
    },
    { title: 'Catalogue', to: '/catalogue', icon: <LocalMall /> },
    {
      title: 'Blog',
      to: '/blog',
      icon: <span className="material-symbols-outlined">edit_document</span>,
      isNew: true,
    },
    { title: 'Top Deals', to: '/top_deals', icon: <LocalOffer /> },
    {
      title: 'Categories',
      to: '/categories',
      icon: (
        <IconContext.Provider value={{}}>
          <BiSolidCategory size={22} />
        </IconContext.Provider>
      ),
    },
    /* authenticated users conditional menu items */
    ...(isAuth
      ? [
          { title: 'Profile', to: '/user', icon: <AccountCircleRounded /> },
          { title: 'Address', to: '/addresses', icon: <LocationOn /> },
          { title: 'Orders', to: '/orders', icon: <ShoppingBasketRounded /> },
          { title: 'Wishlist', to: '/wishlist', icon: <FavoriteRounded /> },
          {
            title: 'Logout',
            fn: () => {
              setAuth(false);
              localStorage.clear();
              window.location.reload();
            },
            icon: <Logout />,
          },
        ]
      : [{ title: 'Login', to: '/login', icon: <Login /> }]),
  ];

  /* settings for iOS */
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      anchor="left"
      open={openLeftDrawer}
      onClose={() => setOpenLeftDrawer(false)}
      onOpen={() => setOpenLeftDrawer(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <MenuList
        sx={{ minWidth: '80vw' }}
        subheader={
          isAuth ? (
            <ListSubheader
              component="div"
              sx={{
                textTransform: 'capitalize',
                color: 'var(--primary)',
                fontSize: '5vw',
                borderBottom: '1px solid rgba(0 0 0 / .3)',
              }}
            >
              Hey,&nbsp;{userData.firstName}&nbsp;{userData.lastName}
            </ListSubheader>
          ) : (
            <ListSubheader
              component="div"
              sx={{
                textTransform: 'capitalize',
                color: 'var(--primary)',
                fontSize: '5vw',
                fontWeight: '700',
                borderBottom: '1px solid rgba(0 0 0 / .3)',
                lineHeight: 1.5,
              }}
            >
              Welcome
              <br />
              Browse, Buy, Save!
            </ListSubheader>
          )
        }
      >
        {menuList.map((item, idx) =>
          item.collapsedItems ? (
            <DrawerItem {...item} key={idx} />
          ) : (
            <MenuItem
              key={idx}
              component={item.to ? Link : 'div'}
              to={item.to ?? '/'}
              selected={item.to === window.location.pathname}
              onClick={() => {
                /* call function if exists */
                item.fn?.();
              }}
            >
              {(() => {
                if (!item.icon) return null;
                return (
                  <ListItemIcon sx={{ color: 'var(--primary)' }}>
                    {item.icon}
                  </ListItemIcon>
                );
              })()}
              <ListItemText
                primary={
                  <div style={classes.title}>
                    {item.title}
                    {(() => {
                      if (!item.isNew) return null;
                      return <div style={classes.newTag}>new</div>;
                    })()}
                  </div>
                }
                sx={{
                  color: 'var(--primary)',
                  fontSize: '1.2rem',
                }}
              />
            </MenuItem>
          ),
        )}
      </MenuList>
    </SwipeableDrawer>
  );
};

/* regex to find root path */
const PathRegex = /\/[a-z]+[^\/]/i;

const DrawerItem = ({ title, to, icon, isNew, collapsedItems, fn }) => {
  const { setOpenLeftDrawer } = useMiscStore();
  /* check if we are in root path  */
  const isCurrentPath = window.location.pathname.startsWith(
    to.match(PathRegex),
  );
  const [collapseOpen, setCollapseOpen] = useState(isCurrentPath);

  return (
    <React.Fragment>
      <MenuItem
        component={!to || isCurrentPath ? 'div' : Link}
        to={to ?? '/'}
        selected={to === window.location.pathname}
        onClick={() => {
          setCollapseOpen(prev => !prev);
          /* call function if exists */
          fn?.();
        }}
      >
        {(() => {
          if (!icon) return null;
          return (
            <ListItemIcon sx={{ color: 'var(--primary)' }}>{icon}</ListItemIcon>
          );
        })()}
        <ListItemText
          primary={
            <div style={classes.title}>
              {title}
              {(() => {
                if (!isNew) return null;
                return <div style={classes.newTag}>new</div>;
              })()}
            </div>
          }
          sx={{
            color: 'var(--primary)',
            fontSize: '1.2rem',
          }}
        />
        {collapseOpen ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={collapseOpen}>
        {collapsedItems?.map((item, i) => (
          <MenuItem
            key={to + i}
            component={item.to ? Link : 'div'}
            to={item.to}
            selected={item.to === window.location.pathname}
            onClick={() => {
              setOpenLeftDrawer(false);
              /* call function if exists */
              item.fn?.();
            }}
          >
            <ListItemText
              primary={
                <div style={classes.title}>
                  {(() => {
                    if (!item.icon) return null;
                    return (
                      <ListItemIcon sx={{ color: 'var(--primary)' }}>
                        {item.icon}
                      </ListItemIcon>
                    );
                  })()}
                  {item.title}
                  {(() => {
                    if (!item.isNew) return null;
                    return <div style={classes.newTag}>new</div>;
                  })()}
                </div>
              }
              sx={{
                color: 'var(--primary)',
                fontSize: '1.2rem',
                paddingLeft: '1rem',
              }}
            />
          </MenuItem>
        ))}
      </Collapse>
    </React.Fragment>
  );
};

export default LeftDrawer;
