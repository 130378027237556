import React, { Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import ProtectedRoute, {
  HaltedRoute,
} from '../../Authentication/ProtectedRoute';
import { useWindowSize } from '../../custom_hooks';

import AllCategoriesPage from '../AllCategoriesPage/AllCategoriesPage';
import BBQLoading from '../Loading/BBQLoading';

const AboutUs = lazy(() => import('../AboutUs/AboutUs'));
const ApplyCoupon = lazy(() => import('../ApplyCoupon/ApplyCoupon'));
const Article = lazy(() => import('../Blog/Article'));
const Blog = lazy(() => import('../Blog/Blog'));
const Cart = lazy(() => import('../Cart/Cart'));
const Checkout = lazy(() => import('../Checkout/Checkout'));
const Error = lazy(() => import('../Error/Error'));
const Policy = lazy(() => import('../Footer/Policy'));
const ShippingPolicy = lazy(() => import('../Footer/ShippingPolicy'));
const Terms = lazy(() => import('../Footer/Terms'));
const LandingPage = lazy(() => import('../Landing Page/LandingPage'));
const PaymentPage = lazy(() => import('../Payment/PaymentPage'));
const ProductPage = lazy(() => import('../ProductPage/ProductPage'));
const Products = lazy(() => import('../Products/Products'));
const TopDealsPage = lazy(() => import('../Products/TopDealsPage'));
const LoginPage = lazy(() => import('../../pages/LoginPage/LoginPage'));
const SignupPage = lazy(() => import('../../pages/SignupPage/SignupPage'));
const AddressView = lazy(() =>
  import('../UserProfile/AddressView/AddressView'),
);
const OrderInfo = lazy(() => import('../UserProfile/OrdersView/OrderInfo'));
const OrdersView = lazy(() => import('../UserProfile/OrdersView/OrdersView'));
const UserProfile = lazy(() => import('../UserProfile/UserProfile'));
const WishlistView = lazy(() =>
  import('../UserProfile/WishlistView/WishlistView'),
);
const Recipes = lazy(() => import('../Recipes/Recipes'));
const CategoryPage = lazy(() => import('../CategoryPage/CategoryPage'));

const Routes = () => {
  const width = useWindowSize();

  useEffect(() => {
    // console.log('width: ', width);
  }, [width]);

  return (
    <Suspense fallback={<BBQLoading />}>
      <div>
        <Switch>
          {/* path to landing page */}
          <Route path="/" exact>
            <LandingPage />
          </Route>
          {/* path to catalogue page */}
          <Route path="/catalogue" exact>
            <Products />
          </Route>
          {/* path to single product page */}
          <Route path="/product/:urlName" exact>
            <ProductPage />
          </Route>
          <Route path="/blog" exact component={Blog}></Route>
          <Route exact path="/blog/:uuid">
            <Article />
          </Route>
          {/* path to cart page */}
          <Route path="/cart" exact>
            <Cart />
          </Route>
          {/* path to single category page */}
          <Route path="/categories" exact>
            <AllCategoriesPage />
          </Route>
          {/* path to single category page */}
          <Route path="/categories/:category" exact>
            <CategoryPage />
          </Route>
          {/* path to applycoupon page*/}
          {width <= 600 && (
            <Route path="/applycoupon" exact>
              <ApplyCoupon />
            </Route>
          )}
          {/* path to checkout page */}
          <ProtectedRoute path="/checkout" exact component={Checkout} />
          {/* path to checkout page */}
          <ProtectedRoute path="/payment" exact component={PaymentPage} />
          {/* path to checkout page */}
          <Route path="/recipes">
            <Recipes />
          </Route>
          {/* path to about us page */}
          <Route path="/about" exact>
            <AboutUs />
          </Route>
          {/* path to terms of services page */}
          <Route path="/terms" exact>
            <Terms />
          </Route>
          {/* path to policies page */}
          <Route path="/policy" exact>
            <Policy />
          </Route>
          {/* path to shipping policies page */}
          <Route path="/shipping_policy" exact>
            <ShippingPolicy />
          </Route>
          {/* path to topDeals page */}
          <Route path="/top_deals" exact>
            <TopDealsPage />
          </Route>
          {/* path to user profile page Desktop View*/}
          <ProtectedRoute exact path="/user" component={UserProfile} />

          {/* path to signup page mobile view*/}
          {/* {width < 600 && ( */}
          <HaltedRoute exact path="/signup" component={SignupPage} />
          {/* )} */}

          {/* {width < 600 && ( */}
          <HaltedRoute
            exact
            path="/signup/:referralCode"
            component={SignupPage}
          />
          {/* )} */}
          {/* path to login page in mobile view*/}
          {/* {width < 600 && ( */}
          <HaltedRoute exact path="/login" component={LoginPage} />
          {/* )} */}
          {/* path to orders in mobile view*/}
          {width < 600 && (
            <ProtectedRoute exact path="/orders" component={OrdersView} />
          )}
          {/* path to  wishlist in mobile view*/}
          {width < 600 && (
            <ProtectedRoute exact path="/wishlist" component={WishlistView} />
          )}
          {/* path to  Addresses in mobile view*/}
          {width < 600 && (
            <ProtectedRoute exact path="/addresses" component={AddressView} />
          )}
          {/* path to each order based on invoiceId*/}
          {width < 600 && (
            <ProtectedRoute
              exact
              path="/orders/order_info/:invoiceId"
              component={OrderInfo}
            />
          )}

          <Route path="/error">
            <Error />
          </Route>
          <Route
            path="*"
            render={props => (
              <Redirect
                to={{
                  pathname: '/error',
                  state: {
                    from: props.location.pathname,
                  },
                }}
              />
            )}
          />
        </Switch>
      </div>
    </Suspense>
  );
};

export default withRouter(Routes);
