import CryptoJS from 'crypto-js';
import toast from './Components/Toast/toast';
import { CAESAR_SHIFT } from './constants';

// validate input fields for signup
export const validateSignupForm = values => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Enter valid email';
  }
  if (values.referralCode && values.referralCode.length !== 8) {
    errors.referralCode = 'Invalid Promocode';
  }
  if (
    values.isValidateReferralCode === false &&
    values.referralCode.length === 8
  ) {
    errors.referralCode = 'This promocode not found ';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 5) {
    errors.password = 'Password must be more than 5 characters';
  }
  return errors;
};

// validating login details
export const validateLoginForm = values => {
  let errors = {};
  if (!values.text) {
    errors.text = 'Required Mobile Number';
  }
  if (!values.password) {
    errors.password = 'Required Password';
  }
  return errors;
};

/* Validate AddressPopup Form inputs */
export const validateAddressForm = (values, pinValid) => {
  var phoneno = /^\d{10}$/;
  var validatePincode = /^\d{6}$/;
  let errors = {};

  if (!values.fullName) {
    errors.fullName = 'Required';
  }

  if (!values.phone.toString().match(phoneno)) {
    errors.phone = 'Invalid phone';
  }
  if (!values.landmark) {
    errors.landmark = 'Required';
  }
  if (!values.houseNo) {
    errors.houseNo = 'Required';
  }
  if (!values.pinCode.match(validatePincode)) {
    errors.pinCode = 'Invalid pincode';
  } else if (!pinValid) {
    errors.pinCode = 'Please select a different location with pincode';
  }

  return errors;
};
/* Validate AddressPopup Form inputs */

export const isObjectEmpty = obj => Object.keys(obj).length === 0;

export const deepCopy = obj => JSON.parse(JSON.stringify(obj));

// cancelTime
export const twoHourTimeout = orderedOn => {
  // console.log('orderedOn...', orderedOn);
  const year = parseInt(orderedOn.slice(0, 4));
  const month = parseInt(orderedOn.slice(5, 7)) - 1;
  const day = parseInt(orderedOn.slice(8, 10));
  const hours = parseInt(orderedOn.slice(11, 13)) + 5;
  const minutes = parseInt(orderedOn.slice(14, 16)) + 30;
  const seconds = parseInt(orderedOn.slice(17, 19));
  const d = new Date(year, month, day, hours, minutes, seconds, 0);
  d.setHours(d.getHours() + 2);
  let now = Date.now();
  return d.valueOf() >= now;
};

//ratingTimeOut
export const ratingTimeOut = (lastModified, invoiceId) => {
  const year = parseInt(lastModified.slice(0, 4));
  const month = parseInt(lastModified.slice(5, 7)) - 1;
  const day = parseInt(lastModified.slice(8, 10));
  const hours = parseInt(lastModified.slice(11, 13)) + 5;
  const minutes = parseInt(lastModified.slice(14, 16)) + 30;
  const seconds = parseInt(lastModified.slice(17, 19));
  const d = new Date(year, month, day, hours, minutes, seconds, 0);
  // console.log('orderId', invoiceId);
  d.setHours(d.getHours() + 24);
  let now = Date.now();
  // console.log(d.valueOf() >= now);
  return d.valueOf() >= now;
};
//istOrderdOn
export const istOrderedOn = orderedOn => {
  const year = parseInt(orderedOn.slice(0, 4));
  const month = parseInt(orderedOn.slice(5, 7)) - 1;
  const day = parseInt(orderedOn.slice(8, 10));
  const hours = parseInt(orderedOn.slice(11, 13)) + 5;
  const minutes = parseInt(orderedOn.slice(14, 16)) + 30;
  const seconds = parseInt(orderedOn.slice(17, 19));
  const d = new Date(year, month, day, hours, minutes, seconds, 0);
  // console.log(d, 'd');
  return d.toString().slice(3, 21);
};
export const istLastModified = lastModified => {
  const year = parseInt(lastModified?.slice(0, 4));
  const month = parseInt(lastModified?.slice(5, 7)) - 1;
  const day = parseInt(lastModified?.slice(8, 10));
  const hours = parseInt(lastModified?.slice(11, 13)) + 5;
  const minutes = parseInt(lastModified?.slice(14, 16)) + 30;
  const seconds = parseInt(lastModified?.slice(17, 19));
  const d = new Date(year, month, day, hours, minutes, seconds, 0);
  // console.log(d, 'd');
  return d?.toString().slice(3, 21);
};

export const wait = duration =>
  new Promise(resolve => setTimeout(resolve, duration));

export function getHash(message) {
  message = String(message);
  return CryptoJS.SHA256(message).toString();
}

export const convertUnits = (value, units) => {
  if (units?.toLowerCase() === 'grams' && value >= 1000)
    return [value / 1000, 'Kgs'];
  return [value, units];
};

export const gcd = (a, b) => {
  if (!b) return a;
  return gcd(b, a % b);
};

export const getFractionFromDecimal = val => {
  val = parseFloat(val);
  if (isNaN(val)) return 'invalid input';
  if (val === Math.trunc(val)) return val.toString();
  const integer_part = Math.trunc(val);
  const decimal_part = val.toString().split('.')[1];
  const divisor = Math.pow(10, decimal_part.length);
  const gcd_val = gcd(decimal_part, divisor);
  return integer_part
    ? `${integer_part} ${decimal_part / gcd_val}/${divisor / gcd_val}`
    : `${decimal_part / gcd_val}/${divisor / gcd_val}`;
};

export const getDecimalFromFraction = val => {
  if (typeof val !== 'string') return 'invalid input';
  if (!val.includes('/')) return val;
  let integer_part = 0,
    fraction_part = val;
  if (val.includes(' ')) {
    integer_part = val.split(' ')[0];
    fraction_part = val.split(' ')[1];
  }
  const [numerator, denominator] = fraction_part.split('/');
  const decimal_part = parseInt(numerator) / parseInt(denominator);
  return parseFloat(parseInt(integer_part) + decimal_part);
};

export const recipeCreatedDateFormatter = Intl.DateTimeFormat(undefined, {
  dateStyle: 'medium',
});

export function caesarEncrypt(plaintext) {
  let ciphertext = '';
  for (let i = 0; i < plaintext.length; i++) {
    let char = plaintext[i];
    if (char.match(/[a-z]/i)) {
      let code = char.charCodeAt();
      let base = char === char.toUpperCase() ? 65 : 97;
      let shiftedChar = String.fromCharCode(
        ((code - base + CAESAR_SHIFT) % 26) + base,
      );
      ciphertext += shiftedChar;
    } else {
      ciphertext += char;
    }
  }
  return ciphertext;
}

export function caesarDecrypt(ciphertext) {
  let plaintext = '';
  for (let i = 0; i < ciphertext.length; i++) {
    let charToDecrypt = ciphertext[i];
    if (charToDecrypt.match(/[a-z]/i)) {
      let base = charToDecrypt === charToDecrypt.toUpperCase() ? 'A' : 'a';
      let decryptedChar = String.fromCharCode(
        ((charToDecrypt.charCodeAt() - base.charCodeAt() - CAESAR_SHIFT + 26) %
          26) +
          base.charCodeAt(),
      );
      plaintext += decryptedChar;
    } else {
      plaintext += charToDecrypt;
    }
  }
  return plaintext;
}

export function encodeId(id) {
  id = Number(id);
  let finalText = '';
  while (id > 0) {
    let digit = id % 10;
    finalText = String.fromCharCode(97 + digit) + finalText;
    id = Math.floor(id / 10);
  }
  return finalText;
}

export function decodeId(id) {
  id = String(id);
  let finalId = 0;
  const len = id.length;
  for (let at = 0; at < len; at++) {
    let charCode = id.charCodeAt(at) - 97;
    finalId += charCode * Math.pow(10, len - 1 - at);
  }
  return finalId;
}

/* Check and remove OS or NA products from the cart */
export const CROS = (
  items,
  products,
  addToCart,
  setCart,
  setOSItems,
  mutateCart,
) => {
  // console.log('CROS called');
  var OSproducts = items.filter(item => {
    const tempItem = products.find(
      product => product.productId === item.productId,
    );
    return tempItem.product_status !== 'AT';
  });
  if (OSproducts.length > 0) {
    // console.log('OSproducts ', OSproducts);
    setOSItems(OSproducts);
  } else {
    repeatOrder(addToCart, setCart, items, products, mutateCart);
  }
  return true;
};
/* Check and remove OS or NA products from the cart */

/*repeatOrder*/
export const repeatOrder = async (
  setCart,
  addToCart,
  items,
  products,
  mutateCart,
) => {
  setCart([]);

  const data = [];
  items.forEach(item => {
    const filteredProduct = products.find(
      product => product.productId === item.productId,
    );
    const cartItem = {
      productId: item.productId,
      title: filteredProduct.title,
      amount: item.price,
      quantity: filteredProduct.quantity,
      image: filteredProduct.image,
      qty: item.quantity,
      units: filteredProduct.units,
      uuid: filteredProduct.uuid,
    };
    data.push({ productId: cartItem.productId, quantity: cartItem.qty });
    addToCart(cartItem);
  });
  mutateCart(data);

  toast.success('items added to cart');
};

// Easing function for smoother scrolling
Math.easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
