import React, { useState } from 'react';
import './RerImage.css';

function RerImage(props) {
  const handleError = e => {
    e.target.onerror = null;
    e.target.src = '../assets/images/error_image.png';
    e.target.style.objectFit = 'contain';
  };

  return <img alt="" {...props} loading="lazy" onError={handleError} />;
}

export default RerImage;
