import create from 'zustand';
import { persist } from 'zustand/middleware';

const useBottomNavStore = create(
  persist(
    set => ({
      /* bottom nav active tab */
      navtab: 0,
      setNavTab: navtab => set({ navtab }),
      showBottomNav: true,
      setShowBottomNav: showBottomNav => set({ showBottomNav }),
      renderBanner: true,
      setRenderBanner: renderBanner => set({ renderBanner }),
    }),
    {
      name: 'bottom-nav',
    },
  ),
);

export default useBottomNavStore;
