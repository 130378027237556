import React, { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import RerImage from '../RerImage/RerImage';
import './CategoryButton.css';

function CategoryButton({ name, imageUrl, alt }, ref) {
  const history = useHistory();

  return (
    <div
      className="cb-category"
      onClick={() =>
        history.push(`/categories/${alt.trim().replaceAll(' ', '_')}`)
      }
      ref={ref}
    >
      <div className="cb-category-image">
        <RerImage src={imageUrl} alt={alt} />
      </div>
      <p>{name.toLowerCase()}</p>
    </div>
  );
}

export default forwardRef(CategoryButton);
