import create from 'zustand';
import { persist } from 'zustand/middleware';

const useUserStore = create(
  persist(
    (set, get) => ({
      /* user creds for fetching data */
      userCreds: {
        uidx: null,
        firstName: '',
        lastName: '',
        isFraud: '',
        createdBy: '',
        createdOn: '',
        lastModifiedOn: '',
        emailAddress: '',
        version: '',
        password: '',
        phone: ''
      },
      setUserCreds: userCreds => set({ userCreds }),
      /* array of user's placed orders */
      allOrders: [],
      setAllOrders: async allOrders => set({ allOrders: await allOrders }),
      addItemsToOrders: (invoiceId, items) => {
        set({
          allOrders: get().allOrders.map(order =>
            order.invoiceId === invoiceId ? { ...order, items: items } : order
          )
        });
      },
      /* user data fetched from db */
      userData: {},
      setUserData: async userData => set({ userData: await userData }),
      /* array of items in user's wishlist */
      watchList: [],
      setWatchList: async watchList => set({ watchList: await watchList })
    }),
    {
      name: 'user-details'
    }
  )
);

export default useUserStore;
