import ArrowBack from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useBottomNavStore from '../../Store/BottomNavStore';
import useProductStore from '../../Store/ProductStore';
import { useWindowSize } from '../../custom_hooks';
import CategoryButton from '../CategoryPage/CategoryButton';
import HeaderMobileView from '../Header/HeaderMobileView';
import './AllCategoriesPage.css';

function AllCategoriesPage() {
  const { tabs } = useProductStore();
  const { setNavTab } = useBottomNavStore();

  const history = useHistory();
  const width = useWindowSize();

  useEffect(() => {
    setNavTab(2);
  }, []);

  return (
    <div className="acat-bg">
      {width <= 600 && <HeaderMobileView />}
      <div className="acat-main">
        <div className="acat-top">
          <IconButton sx={{ ml: 2 }} onClick={() => history.push('/catalogue')}>
            <ArrowBack color="primary" />
          </IconButton>
          <h3 className="acat-title">Shop By Category</h3>
        </div>
        <Divider />
        <div className="acat-btns">
          {tabs?.map((tab, idx) => (
            <CategoryButton {...tab} key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AllCategoriesPage;
