import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import useTheme from '@mui/material/styles/useTheme';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import toast from '../Toast/toast';
import useAuthStore from '../../Store/AuthStore';
import useCartStore from '../../Store/CartStore';
import useMiscStore from '../../Store/MiscStore';
import useUserStore from '../../Store/UserStore';
import { getRequest, postRequest } from '../../api_calls';
import {
  CART_ADD_URL,
  SIGNUP_URL,
  USER_CHECK_EXISTING_URL,
  VALIDATE_REFERRALCODE_URL,
} from '../../api_config';
import { LOGIN_KEY, TOKEN_KEY } from '../../constants';
import './Signup.css';
import { auth, firebase } from '../../Authentication/firebase';
import validatePassword from './validate_password';

/*SignupForm and RegisterForm*/
export const CreateAccount = () => {
  /* zustand vars */
  const { setFormView, setPass, mobileNumber, setMobileNumber } =
    useMiscStore();
  /* zustand vars */

  /* input error boolean */
  const [error, setError] = useState(false);
  // user existing text
  const [isExisting, setIsExisting] = useState('');
  // input error text
  const [errorMessage, setErrorMessage] = useState('');
  // signup otp
  const [otp, setOtp] = useState();
  // boolean for rendering signup form
  const [show, setshow] = useState(false);
  // recaptcha verification boolean
  const [final, setfinal] = useState('');
  // mobile country code to prepend to user input mobile no.
  const countryCode = '+91';

  /* phone number validation */
  function validatePhoneNumber(number) {
    var re = /^\d{10}$/im;
    return re.test(number);
  }
  /* phone number validation */

  const { mutate: checkExistingUser } = useMutation({
    mutationFn: data => postRequest(USER_CHECK_EXISTING_URL, data),
    onSuccess: data => {
      if (data) {
        setIsExisting('User already exists');
      } else {
        signin();
      }
    },
  });

  /* signin */
  const signin = () => {
    if (!validatePhoneNumber(mobileNumber)) {
      setError(true);
      setErrorMessage('Invalid Mobile Number');
      return;
    }
    setError(false);
    setErrorMessage('');
    let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    auth
      .signInWithPhoneNumber(countryCode + mobileNumber, verify)
      .then(result => {
        setfinal(result);
        setshow(true);
      })
      .catch(err => {
        alert(err);
        window.location.reload();
      });
  };
  /* signin */

  /* check for user existing */
  const handleCheckUserExistsRequest = () => {
    checkExistingUser({
      phoneNumber: mobileNumber,
    });
  };
  /* check for user existing */

  // Validate OTP
  const ValidateOtp = () => {
    if (otp === '' || final === null) {
      setError(true);
      setErrorMessage('Invalid OTP');
      return;
    }
    setError(false);
    setFormView(2);
    setErrorMessage('');
    final
      .confirm(otp)
      .then(result => {
        setPass(true);
      })
      .catch(err => {
        setError(true);
        setErrorMessage('Invalid OTP');
      });
  };

  return (
    <div className="login-pop">
      <div className="pop-title">Create Account</div>
      <div style={{ display: !show ? 'block' : 'none' }}>
        <br />
        <TextField
          label="Mobile Number"
          onChange={e => {
            setMobileNumber(e.target.value);
          }}
          sx={{ width: '100%', my: '2%' }}
          type="tel"
          value={mobileNumber}
          name="mobileNumber"
        />
        <br />
        <div id="recaptcha-container"></div>
        {error && <p className="error">{errorMessage}</p>}
        {isExisting !== '' && <p className="error">{isExisting}</p>}
        <Button
          variant="contained"
          sx={{ my: '2%', mx: 'auto' }}
          onClick={handleCheckUserExistsRequest}
        >
          Send OTP
        </Button>
      </div>
      <div style={{ display: show ? 'block' : 'none' }}>
        <TextField
          type="text"
          placeholder={'Enter your OTP'}
          sx={{ width: '100%', my: '2%' }}
          onChange={e => {
            setOtp(e.target.value);
          }}
        />
        <br />
        <Button
          variant="contained"
          sx={{ my: '2%', mx: 'auto' }}
          onClick={ValidateOtp}
        >
          Verify
        </Button>
        {error ? <p className="error">{errorMessage}</p> : <div></div>}
      </div>
      <div>Existing user?</div>
      <Button
        variant="contained"
        sx={{ my: '2%', mx: 'auto' }}
        onClick={() => setFormView(0)}
      >
        Login
      </Button>
    </div>
  );
};

/* Signup Form */
export const AfterVerify = () => {
  /* zustand vars */
  const { setShowSignUp, setFormView } = useMiscStore(state => state);
  const { pass, mobileNumber } = useMiscStore();
  const { setAuth } = useAuthStore();
  const { setUserCreds, userCreds } = useUserStore();
  const { cart } = useCartStore();
  /* zustand vars */

  // input values states
  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    referralCode: '',
    isValidateReferralCode: false,
  });
  // error text
  const [errors, setErrors] = useState({});
  // boolean for valid data
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  // boolean - checkbox of agreeing terms and condition
  const [agreed, setAgreed] = useState(false);
  //enable and disable Register& Login button
  const [disable, setDisable] = useState(false);

  // mui theme object
  const theme = useTheme();

  /* validate refferal code */
  const { data: refData } = useQuery({
    queryKey: ['validate-referral-code'],
    queryFn: () =>
      getRequest(`${VALIDATE_REFERRALCODE_URL}/${values.referralCode}`),
    enabled: !!values.referralCode && values.referralCode.length === 8,
  });

  useEffect(() => {
    setValues({ ...values, isValidateReferralCode: refData ?? false });
  }, [refData]);

  const { mutate: signup } = useMutation({
    mutationFn: data => postRequest(SIGNUP_URL, data),
    onSuccess: data => {
      if (data) {
        // console.log(response.data);
        localStorage.setItem(TOKEN_KEY, data.token);
        localStorage.setItem(LOGIN_KEY, data.login);
        toast.success('Login successful!');
        add();
        setDataIsCorrect(true);
        setUserCreds({
          ...userCreds,
          phone: mobileNumber,
          email: values?.email,
          firstName: values.firstName,
          lastName: values.lastName,
        });
        setDisable(false);
        setAuth(true);
      }
    },
    onSettled: () => {
      setValues({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        referralCode: '',
      });

      setShowSignUp(false);
      setFormView(0);
    },
  });

  const { mutate: addToCart } = useMutation({
    mutationFn: data => postRequest(CART_ADD_URL, data),
  });

  // handle values state change
  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value.trim() });
  };

  useEffect(() => {
    // checking for errors and proceeding to signup
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      setDisable(true);
      signup({
        firstName: values.firstname,
        lastName: values.lastname,
        email: values?.email,
        customerType: 'B2C',
        password: values.password,
        phoneNumber: mobileNumber,
        referralCode: values.referralCode,
      });
    }
  }, [errors]);

  //add
  const add = async () => {
    let input = cart?.map(c => {
      return { productId: c.productId, quantity: c.qty };
    });
    addToCart(input);
  };

  useEffect(() => {
    // console.log('agreed: ' + agreed);
    // console.log('disable: ' + disable);
  }, [agreed, disable]);

  // for validating inputs for errors
  const handleRegisterRequest = e => {
    e.preventDefault();

    setErrors(validatePassword(values));
    setDataIsCorrect(true);
  };

  if (!pass) return null;

  return (
    <div className="login-pop">
      <div className="pop-title">User Registration</div>
      <>
        <div className="create-form" autoComplete="off">
          <section className="groupedText">
            <TextField
              type="text"
              label="First Name"
              name="firstname"
              size="small"
              helperText={
                errors.firstname && <p className="error">{errors.firstname}</p>
              }
              value={values.firstname}
              onChange={handleChange}
            />
            <TextField
              type="text"
              label="Last Name"
              name="lastname"
              size="small"
              helperText={
                errors.lastname && <p className="error">{errors.lastname}</p>
              }
              value={values.lastname}
              onChange={handleChange}
            />
          </section>
          <TextField
            type="text"
            label="Promo code(Optional)"
            name="referralCode"
            size="small"
            className="fields"
            helperText={
              errors.referralCode && (
                <p className="error">{errors.referralCode}</p>
              )
            }
            onChange={handleChange}
            value={values.referralCode}
          />
          <TextField
            type="email"
            label="Email(Optional)"
            name="email"
            size="small"
            className="fields"
            helperText={errors.email && <p className="error">{errors.email}</p>}
            onChange={handleChange}
            value={values.email}
          />
          <TextField
            type="password"
            label="Password"
            name="password"
            className="fields"
            size="small"
            helperText={
              errors.password && <p className="error">{errors.password}</p>
            }
            value={values.password}
            onChange={handleChange}
          />
        </div>
      </>
      <div>
        <div className="check-terms">
          <input
            type="checkbox"
            id="terms"
            onClick={e => {
              handleChange(e);
              setAgreed(!agreed);
            }}
          />
          <label htmlFor="terms">
            I agree to the
            <Link
              to={`/terms`}
              target="_blank"
              style={{
                textDecoration: 'none',
                listStyle: 'none',
                color: 'blue',
                margin: '0 3px',
              }}
              exact
            >
              Terms of Service
            </Link>
            and
            <Link
              to={`/policy`}
              target="_blank"
              exact
              style={{
                textDecoration: 'none',
                listStyle: 'none',
                color: 'blue',
                margin: '0 3px',
              }}
            >
              Privacy Policy
            </Link>
          </label>
        </div>
        {errors.agreed && <p className="error">{errors.agreed}</p>}
      </div>
      <br />
      {!disable ? (
        <Button
          variant="contained"
          sx={{ my: theme.spacing(2) }}
          onClick={handleRegisterRequest}
          className="register"
          disabled={!agreed}
        >
          Register & Login
        </Button>
      ) : (
        <Box sx={{ my: theme.spacing(2) }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};
