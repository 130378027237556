import CLose from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import toast from '../Toast/toast';
import useAuthStore from '../../Store/AuthStore';
import useBottomNavStore from '../../Store/BottomNavStore';
import useCartStore from '../../Store/CartStore';
import useMiscStore from '../../Store/MiscStore';
import useUserStore from '../../Store/UserStore';
import { postRequest } from '../../api_calls';
import { CART_ADD_URL, LOGIN_URL } from '../../api_config';
import { LOGIN_KEY, TOKEN_KEY } from '../../constants';
import ForgotPasswordView from './ForgotPasswordView';
import { AfterVerify, CreateAccount } from './Signup';
import './Signup.css';
import validate from './validate';

/*signup and login forms*/
const Login = ({ history }) => {
  /* zustand vars */
  const { setAuth } = useAuthStore();
  const { setUserB2b } = useAuthStore();
  const { showSignUp, setShowSignUp, formView, setFormView } = useMiscStore();
  const { setNavTab } = useBottomNavStore();
  const { userCreds, setUserCreds } = useUserStore();
  const { cart } = useCartStore();
  /* zustand vars */

  // boolean for valid data
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  // error text for wrong password
  const [wrongPassword, setWrongPassword] = useState(null);
  // error texts for different fields
  const [errors, setErrors] = useState({
    wrongPassword: '',
    text: '',
    password: '',
  });
  // login input values
  const [values, setValues] = useState({
    text: '',
    password: '',
  });

  const { mutate: login } = useMutation({
    mutationFn: data => postRequest(LOGIN_URL, data),
    onSuccess: data => {
      localStorage.setItem(TOKEN_KEY, data.token);
      localStorage.setItem(LOGIN_KEY, data.login);
      toast.success('Login successful!');
      add();
      setUserB2b(false);
      setWrongPassword('');
      setUserCreds({ ...userCreds, phone: values.text });
      setShowSignUp(false);
      setNavTab(0);
      setAuth(true, () => {});
      setValues({
        text: '',
        password: '',
      });
    },
    onError: () => {
      setWrongPassword('Please enter correct details!');
    },
  });

  const { mutate: addToCart } = useMutation({
    mutationFn: data => postRequest(CART_ADD_URL, data),
  });

  // for closing model on clicking outside the modal
  function handlemodelClose() {
    if (formView === 3 || formView === 2 || formView === 1) {
      return;
    } else {
      setShowSignUp(false);
      setFormView(0);
      setNavTab(0);
      setValues({
        text: '',
        password: '',
      });
    }
  }

  // for closing modal on clicking close button
  function handleClose() {
    setShowSignUp(false);
    setFormView(0);
    setValues({
      text: '',
      password: '',
    });
  }

  useEffect(() => {
    // checking for errors and proceeding to login
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      setWrongPassword('');
      login({
        password: values.password,
        phoneNumber: values.text,
      });
    }
  }, [errors]);

  //add
  const add = () => {
    let input = cart?.map(c => {
      return { productId: c.productId, quantity: c.qty };
    });
    addToCart(input);
  };

  // controlling values
  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // setting dataiscorrect to true for proceeding to login
  const handleLoginRequest = e => {
    setErrors(validate(values));
    setDataIsCorrect(true);
  };

  /*Login from*/
  return (
    <>
      <Modal open={showSignUp} onClose={handlemodelClose}>
        <div className="form" autoComplete="off">
          <IconButton
            className="remove"
            onClick={() => {
              handleClose();
              setNavTab(0);
            }}
          >
            <CLose />
          </IconButton>
          {/* conditionally rendering formViews */}
          {formView === 0 && (
            <>
              <div className="pop-title">Login</div>
              <div className="create-form">
                <TextField
                  style={{ marginTop: '10px' }}
                  label="Mobile Number"
                  value={values.text}
                  onChange={handleChange}
                  id="email"
                  type="tel"
                  name="text"
                  onKeyDown={e => e.key === 'Enter' && handleLoginRequest()}
                />
                {errors.text && <p className="error">{errors.text}</p>}
                <TextField
                  style={{ marginTop: '10px' }}
                  label="Password"
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  id="password"
                  type="password"
                  onKeyDown={e => e.key === 'Enter' && handleLoginRequest()}
                />
              </div>
              <span>
                <Typography
                  variant="subtitle2"
                  component="span"
                  style={{
                    fontSize: '15px',
                    color: 'blue',
                    cursor: 'pointer',
                    display: 'inline',
                    padding: 0,
                  }}
                  onClick={() => setFormView(3)}
                >
                  Forgot Password?
                </Typography>
              </span>
              {errors.password && <p className="error">{errors.password}</p>}
              {wrongPassword && <p className="error">{wrongPassword}</p>}
              <br />
              <Button
                style={{
                  background: 'var(--primary)',
                  color: 'white',
                  cursor: 'pointer',
                  padding: '3px 9px',
                }}
                variant="contained"
                type="submit"
                onClick={handleLoginRequest}
              >
                Login
              </Button>
              <Typography style={{ fontSize: '15px', color: 'black' }}>
                Don't Have An Account?
              </Typography>
              <span>
                <Button
                  variant="contained"
                  style={{
                    background: 'var(--primary)',
                    color: 'white',
                    cursor: 'pointer',
                    padding: '3px 9px',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0 auto',
                  }}
                  onClick={() => setFormView(1)}
                >
                  Register
                </Button>
              </span>
            </>
          )}
          {formView === 1 && <CreateAccount />}
          {formView === 2 && <AfterVerify />}
          {formView === 3 && <ForgotPasswordView />}
        </div>
      </Modal>
    </>
  );
};

export default withRouter(Login);
