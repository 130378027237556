// localstorage token key
export const TOKEN_KEY = 'X_AUTH_TOKEN';

export const MAX_PROMOCASH = 80;

export const TRACKING_ID = 'G-YG9LD9QBSB';

export const ACCESS_TOKEN = 'testing-token';

export const LOGIN_KEY = 'LOGIN_KEY';

export const CAESAR_SHIFT = 5;

export const EMAIL_ID = 'info@root-enroute.com';
