import CloseOutlined from '@mui/icons-material/CloseOutlined';
import Search from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { matchSorter } from 'match-sorter';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useProductStore from '../../Store/ProductStore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function SearchBox({
  search,
  setSearch,
  width = '50%',
  padding,
}) {
  const { products, popular } = useProductStore();

  const history = useHistory();

  const generateTags = (prods, showTags = true) => {
    const allTags = [];
    let tempId = 1;
    prods?.forEach(product => {
      allTags.push({
        id: tempId++,
        label: product.title.trim(),
        pId: product.productId,
        urlName: product?.urlName,
      });
      if (showTags && product.tags != null && product.product_status !== 'NA') {
        const tags = product.tags.split('/');
        for (const tag of tags) {
          if (!tag.trim()) continue;
          allTags.push({
            id: tempId++,
            label: tag.trim(),
            pId: product.productId,
            urlName: product?.urlName,
          });
        }
      }
    });
    const finalOptions = (
      search
        ? matchSorter(allTags, search, {
            keys: ['label'],
          })
        : allTags
    ).slice(0, 5);
    return finalOptions;
  };

  const popularProducts = useMemo(() => {
    if (!popular) return [];
    return products
      ?.filter(product => popular.includes(product.productId))
      .filter(popular => popular.product_status !== 'NA');
  }, [products, popular]);

  const tagList = useMemo(() => {
    return [
      ...generateTags(search ? products : popularProducts, !!search),
      ...(search && [
        {
          id: null,
          label: 'Search in Recipes...',
          icon: Search,
          pId: null,
          urlName: 'recipes',
        },
      ]),
    ];
  }, [products, search, popularProducts]);

  return (
    <Autocomplete
      freeSolo
      autoHighlight
      selectOnFocus
      filterOptions={(options, state) => options}
      options={tagList}
      value={search}
      // key={option => option.id}
      onChange={(e, newValue) => {
        setSearch('');
        const name = newValue?.urlName;
        if (!newValue) return;
        if (name.includes('recipes')) {
          history.push(`/recipes/all?search=${search}`);
        } else {
          history.push(`/product/${name}`);
        }
      }}
      onFocus={() => {
        document
          .getElementById('categoriesList')
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
      }}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            style={{ color: option.icon && 'var(--primary)' }}
            key={props.id}
          >
            {option.icon && (
              <>
                <option.icon color="primary" />
                &nbsp;
              </>
            )}
            {option.label}
          </li>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          size="small"
          placeholder="Search for combos and more..."
          onChange={e => setSearch(e.target.value)}
          sx={{
            "input[type='search']::-webkit-search-cancel-button": {
              display: 'none',
            },
          }}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  style={{
                    color: 'grey',
                    fontSize: '1.4rem',
                    margin: '0 0.3rem',
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      // clearIcon={
      //   <CloseOutlined
      //     style={{
      //       color: 'grey',
      //       fontSize: '1.3rem',
      //       backgroundColor: 'white',
      //       zIndex: 10,
      //     }}
      //   />
      // }
      style={{
        width,
        height: '100%',
        padding,
        outline: 'none',
        border: 'none',
        fontSize: '1em',
        color: '#505050',
        margin: '0 0.5rem',
        backgroundColor: 'white',
        borderRadius: '6px',
      }}
    />
  );
}
