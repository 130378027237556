import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom';
import toast, { Toaster } from 'react-hot-toast';
import App from './App';
import {
  LOGIN_URL,
  PASSWORD_UPDATE_URL,
  USER_CHECK_EXISTING_URL,
} from './api_config';
import { axiosInstance } from './axios_assets';
import { LOGIN_KEY, TOKEN_KEY } from './constants';
import './index.css';

// React Query Client
const queryClient = new QueryClient({
  defaultOptions: {},
  queryCache: new QueryCache({
    onError: error => {
      toast.error('Something went wrong!');
      // toast.error(error.message);
    },
  }),
  mutationCache: new MutationCache({
    onError: error => {
      if (error?.config?.url !== LOGIN_URL)
        toast.error('Something went wrong!');
      // toast.error(error.message);
    },
  }),
});
/* 
  new primary
  light:#33b7b4
  main:#23807e
  dark:#185958

*/

/* MUI Theme object setup */
const theme = createTheme({
  typography: {
    fontFamily: ['Nunito', 'Segoe UI', 'Verdana', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      // light: '#6efcfe',
      // main: '#41bbca',
      // dark: '#33b0b1',
      light: '#33b7b4',
      main: '#23807e',
      dark: '#185958',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6efcfe',
      main: '#41bbca',
      dark: '#33b0b1',
      contrastText: '#fff',
    },
  },
  spacing: 8,
});

/* 
  xs, extra-small: 0px
  sm, small: 600px
  md, medium: 900px
  lg, large: 1200px
  xl, extra-large: 1536px
*/

/* intercepting request objects */
axiosInstance.interceptors.request.use(
  request => {
    // adding token to header of each request
    if (localStorage.getItem(TOKEN_KEY)) {
      request.headers.Authorization = `Bearer ${localStorage.getItem(
        TOKEN_KEY,
      )}`;
    }
    if (localStorage.getItem(LOGIN_KEY)) {
      request.headers.login = localStorage.getItem(LOGIN_KEY);
    }

    return request;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  },
);

const handleSessionExpiration = error => {
  const { url } = error.config;

  localStorage.clear();

  if (url !== LOGIN_URL) {
    toast.error('Session Expired!');
  }

  const exemptUrls = [LOGIN_URL, PASSWORD_UPDATE_URL, USER_CHECK_EXISTING_URL];

  if (!exemptUrls.includes(url)) {
    window.location.assign(window.location.origin);
  }
};

/* intercepting response objects */
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      handleSessionExpiration(error);
    }
    return Promise.reject(error);
  },
);

/* Rendering the App */
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <Toaster position="bottom-center" toastOptions={{ duration: 3000 }} />
      <App />
    </ThemeProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
