import React from 'react';
import './BBQLoading.css';
import { ReactComponent as BBQRod } from './bbq-loader-images/bbq-rod.svg';
import { ReactComponent as Tomato } from './bbq-loader-images/tomato.svg';
import { ReactComponent as GreenPepper } from './bbq-loader-images/green-capsicum.svg';
import { ReactComponent as YellowPepper } from './bbq-loader-images/yellow-capsicum.svg';
import { ReactComponent as RedChilly } from './bbq-loader-images/red-chilly.svg';

function BBQLoading() {
  return (
    <div className="loading-background">
      <div className="loading-container">
        <div className="bbq-rod">
          <BBQRod />
        </div>
        <div className="veggie i">
          <Tomato />
        </div>
        <div className="veggie ii">
          <GreenPepper />
        </div>
        <div className="veggie iii">
          <RedChilly />
        </div>
        <div className="veggie iv">
          <YellowPepper />
        </div>
        <div className="veggie v">
          <Tomato />
        </div>
      </div>
    </div>
  );
}

export default BBQLoading;
