import create from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(
  persist(
    set => ({
      /* boolean value for user is b2b or not */
      userB2b: false,
      setUserB2b: userB2b => set({ userB2b }),
      /* boolean value for user login or not */
      isAuth: false,
      setAuth: (isAuth, callback = null) => {
        set({ isAuth });
        // any callback function passed as parameter
        if (callback && typeof callback === 'function') callback();
      },
      /* firebase remote config data */
      remoteConfigData: {},
      setRemoteConfigData: remoteConfigData => set({ remoteConfigData }),
      /* recipe admin login */
      isRecipeAdminLogin: false,
      setIsRecipeAdminLogin: isRecipeAdminLogin => set({ isRecipeAdminLogin }),
    }),
    // key for localstorage item
    { name: 'authentication-settings' },
  ),
);

export default useAuthStore;
