import create from 'zustand';

const useMiscStore = create(set => ({
  /* user profile desktop view active tab value */
  tab: 0,
  setTab: tab => set({ tab }),
  /* boolean for opening signup/login modal */
  showSignUp: false,
  setShowSignUp: showSignUp => set({ showSignUp }),
  /* active form component in signup/login modal (used in login popup) */
  formView: 0,
  setFormView: formView => set({ formView }),
  /* active user mobile number */
  mobileNumber: '',
  setMobileNumber: mobileNumber => set({ mobileNumber }),
  /* boolean for rendering signup form */
  pass: false,
  setPass: pass => set({ pass }),
  /* boolean for add address popup open */
  addAddress: false,
  setAddAddress: addAddress => set({ addAddress }),
  /* boolean for address view */
  addressView: 0,
  setAddressView: addressView => set({ addressView }),
  /* leftDrawer boolean */
  openLeftDrawer: false,
  setOpenLeftDrawer: openLeftDrawer => set({ openLeftDrawer }),
  /* recipe profile tab value */
  recipeProfileTab: 0,
  setRecipeProfileTab: recipeProfileTab => set({ recipeProfileTab }),
  /* render single toast id */
  toastId: null,
  setToastId: toastId => set({ toastId }),
}));

export default useMiscStore;
