import firebase from 'firebase';

/* firebase configuration data */
const firebaseConfig = {
  apiKey: 'AIzaSyCIAnTInSvGfRuQmXve0vKiQMMO1MUnP0o',
  authDomain: 'root-enroute-login.firebaseapp.com',
  projectId: 'root-enroute-login',
  storageBucket: 'root-enroute-login.appspot.com',
  messagingSenderId: '572909700629',
  appId: '1:572909700629:web:6149f895da79988c0a701c',
  measurementId: 'G-JG0KSLFE0G',
};

const app = firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();

const remoteConfig = firebase.remoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 20000;
let isRemoteConfigDataFetched;
remoteConfig
  .fetchAndActivate()
  .then(res => {
    isRemoteConfigDataFetched = res;
  })
  .catch(err => {
    console.error(err);
  });

export { auth, firebase, remoteConfig, isRemoteConfigDataFetched };
