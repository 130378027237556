import toast from 'react-hot-toast';
import Warning from '@mui/icons-material/Warning';
import styled from '@mui/material/styles/styled';

const ToastBox = styled('div')({
  backgroundColor: 'white',
  padding: '0.5em 0.8em',
  borderRadius: '0.5em',
  color: '#333',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5em',
  boxShadow: '0 1px 2px 1px #0003',
});

toast.warn = message => {
  toast.custom(
    <ToastBox>
      <Warning style={{ fill: '#FFB74D' }} fontSize="small" />
      {message}
    </ToastBox>,
  );
};

export default toast;
