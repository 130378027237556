// validating login details
const validate = values => {
  let errors = {};
  if (!values.text) {
    errors.text = 'Required Mobile Number';
  }
  if (!values.password) {
    errors.password = 'Required Password';
  }
  return errors;
};

export default validate;
