// validate input fields for signup
const validatePassword = values => {
  let errors = {};

  if (!values.firstname) {
    errors.firstname = 'First Name is required';
  }
  if (!values.lastname) {
    errors.lastname = 'Last Name is required';
  }
  if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Enter valid email';
  }
  if (values.referralCode && values.referralCode.length !== 8) {
    errors.referralCode = 'Invalid Promocode';
  }
  if (
    values.isValidateReferralCode === false &&
    values.referralCode.length === 8
  ) {
    errors.referralCode = 'This promocode not found ';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 5) {
    errors.password = 'Password must be more than 5 characters';
  }
  return errors;
};
export default validatePassword;
