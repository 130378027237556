import create from 'zustand';

const useProductStore = create(set => ({
  /* array of all b2c products */
  products: [],
  setProducts: async products => set({ products: await products }),
  /* array of all top suggestions and top suggestions*/
  suggestions: [],
  setSuggestions: async suggestions => set({ suggestions: await suggestions }),
  /* array of all top suggestions and top deals*/
  deals: [],
  setDeals: async deals => set({ deals: await deals }),
  /* array of popular products ids */
  popular: [],
  setPopular: async popular => set({ popular: await popular }),
  /* search term string */
  search: '',
  setSearch: search => set({ search }),
  /* categories tabs data and toggle handling */
  tabs: [],
  setTabs: input => set({ tabs: input }),
  categories: [],
  setCategories: input => set({ categories: input }),
  selectedCategory: [],
  setSelectedCategory: input => set({ selectedCategory: input }),
  category: '',
  setCategory: category => set({ category }),
  productUnits: [],
  setProductUnits: productUnits => set({ productUnits }),
  blog: [],
  setBlog: input => set({ blog: input }),
  slides: [],
  setSlides: input => set({ slides: input }),
}));

export default useProductStore;
