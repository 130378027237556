import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/*useWindowSize*/
export const useWindowSize = () => {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
/*useWindowSize*/

/*useWindowSize*/
export const useWindowHeight = () => {
  const [size, setSize] = useState(0);
  function updateSize() {
    setSize(window.innerHeight);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
/*useWindowSize*/

/* async functions to avoid state change warnings */
export function useAsync(asyncFn, onSuccess) {
  useEffect(() => {
    let isActive = true;
    asyncFn().then(data => {
      if (isActive) onSuccess(data);
    });
    return () => {
      isActive = false;
    };
  }, [asyncFn, onSuccess]);
}

/* custom useSearchParams from react-router-dom v6 */

export function useSearchParams() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const getParam = name => searchParams.get(name);

  const setParam = (name, value) => {
    searchParams.set(name, value);
    history.replace({ ...location, search: searchParams.toString() });
  };

  return [getParam, setParam];
}
