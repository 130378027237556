import CssBaseline from '@mui/material/CssBaseline';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import BottomNav from './Components/BottomNav/BottomNav';
import Routes from './Components/Routes/Routes';
import Login from './Components/Signup/Login';
import {
  isRemoteConfigDataFetched,
  remoteConfig,
} from './Authentication/firebase';
import useAuthStore from './Store/AuthStore';
import useBottomNavStore from './Store/BottomNavStore';
import useCartStore from './Store/CartStore';
import useCheckoutStore from './Store/CheckoutStore';
import usePaymentStore from './Store/PaymentStore';
import useProductStore from './Store/ProductStore';
import useUserStore from './Store/UserStore';
import { getRequest, postRequest } from './api_calls';
import {
  ALL_ADDRESS_URL,
  ALL_BLOG_URL,
  ALL_CART_URL,
  ALL_CATEGORIES_URL,
  ALL_PRODUCTS_URL,
  ALL_SLIDES_URL,
  ALL_WISHLIST_ITEMS_URL,
  PAYMENT_DETAILS_URL,
  POPULAR_PRODUCTS_URL,
  PRODUCT_SUGGESTIONS_URL,
  PRODUCT_UNITS_LIST_URL,
  USER_DATA_URL,
} from './api_config';
import { useWindowSize } from './custom_hooks';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

/* Root App Component */
const App = () => {
  /* zustand vars */
  const { userCreds, setUserData, setWatchList } = useUserStore();
  const { isAuth, setAuth, setRemoteConfigData } = useAuthStore();
  const {
    setProducts,
    setTabs,
    setCategories,
    products,
    setDeals,
    setSuggestions,
    setProductUnits,
    setSlides,
    setBlog,
    setPopular,
  } = useProductStore();
  const width = useWindowSize();
  const { showBottomNav, setNavTab, setShowBottomNav } = useBottomNavStore();
  const { setDetails } = useCheckoutStore();
  const { setCart } = useCartStore();

  const setPaymentKey = usePaymentStore(state => state.setKey);
  /* zustand vars */

  const { data: productsData } = useQuery({
    queryKey: ['products'],
    queryFn: () => postRequest(ALL_PRODUCTS_URL),
  });

  useEffect(() => {
    setProducts(productsData ?? []);
  }, [productsData]);

  const { data: userData } = useQuery({
    queryKey: ['user'],
    queryFn: () =>
      postRequest(USER_DATA_URL, {
        phoneNumber: userCreds.phone,
      }).catch(error => {
        if (error.response.status === 401) {
          setAuth(false);
        }
      }),
    enabled: isAuth,
  });

  useEffect(() => {
    setUserData(userData);
  }, [userData]);

  /* slides */
  const { data: slidesData } = useQuery({
    queryKey: ['slides'],
    queryFn: () => getRequest(ALL_SLIDES_URL),
  });

  useEffect(() => {
    if (slidesData) setSlides(slidesData);
  }, [slidesData]);

  /* categories */
  const { data: categoriesData } = useQuery({
    queryKey: ['categories'],
    queryFn: () => getRequest(ALL_CATEGORIES_URL),
  });

  useEffect(() => {
    if (!categoriesData) return;
    setTabs(
      categoriesData
        ?.filter(category => category.name !== 'ALL')
        ?.map(category => {
          return {
            ...category,
            toggle: false,
            alt: category.name.toLowerCase(),
          };
        }),
    );
    setCategories(
      categoriesData?.map(category => {
        return {
          ...category,
          toggle: false,
          alt: category.name.toLowerCase(),
        };
      }),
    );
  }, [categoriesData]);

  const { data: dealsAndSuggestionsData } = useQuery({
    queryKey: ['deals-and-suggestions'],
    queryFn: () => getRequest(PRODUCT_SUGGESTIONS_URL),
  });

  useEffect(() => {
    setDeals(dealsAndSuggestionsData?.topDeals);
    setSuggestions(dealsAndSuggestionsData?.topSuggestions);
  }, [dealsAndSuggestionsData]);

  const { data: productUnitsData } = useQuery({
    queryKey: ['products', 'units'],
    queryFn: () => getRequest(PRODUCT_UNITS_LIST_URL),
  });

  useEffect(() => {
    setProductUnits(productUnitsData);
  }, [productUnitsData]);

  /* user cart data */
  const { data: cartData } = useQuery({
    queryKey: ['cart'],
    queryFn: () => getRequest(ALL_CART_URL),
    enabled: isAuth && productsData?.length > 0,
  });

  useEffect(() => {
    if (cartData?.statusResponse.statusCode === 200) {
      let cartDetails = [];
      cartDetails =
        products &&
        cartData?.cart?.map(cartItem => {
          let product = products?.find(p => {
            return p.productId === cartItem.productId;
          });
          // console.log('product', product);
          return {
            productId: cartItem?.productId,
            title: product?.title,
            amount: product?.amount,
            image: product?.image,
            qty: cartItem?.quantity,
            units: product?.units,
            quantity: product?.quantity,
            maxQuantity: product?.maxQuantity,
          };
        });
      setCart(cartDetails && cartDetails?.reverse());
    }
  }, [cartData]);

  /* all blogs */
  const { data: blogsData } = useQuery({
    queryKey: ['blogs'],
    queryFn: () => getRequest(ALL_BLOG_URL),
  });

  useEffect(() => {
    if (!blogsData) return;
    const formatter = Intl.DateTimeFormat(undefined, {
      dateStyle: 'medium',
    });
    let blogs = blogsData.map(b => {
      let d = b.lastModified;
      d[1] = d[1] - 1;
      const date = new Date(...d);
      return {
        title: b.title,
        image: b.image,
        category: b.category,
        description: b.description,
        lastModified: formatter.format(date),
        productId: b.productId,
      };
    });
    setBlog(blogs);
  }, [blogsData]);

  /* get payment key */
  const { data: paymentKeyData } = useQuery({
    queryKey: ['payment-key'],
    queryFn: () => postRequest(PAYMENT_DETAILS_URL),
    enabled: isAuth,
  });

  useEffect(() => {
    setPaymentKey(paymentKeyData);
  }, [paymentKeyData]);

  /* popular products for search */
  const { data: popularData } = useQuery({
    queryKey: ['products', 'popular'],
    queryFn: () => getRequest(POPULAR_PRODUCTS_URL),
  });

  useEffect(() => {
    setPopular(popularData ?? []);
  }, [popularData]);

  /* user addresses */
  const { data: userAddressesData } = useQuery({
    queryKey: ['user', 'addresses'],
    queryFn: () => getRequest(ALL_ADDRESS_URL),
    enabled: isAuth,
  });

  useEffect(() => {
    if (userAddressesData) setDetails(userAddressesData);
  }, [userAddressesData]);

  /* user wishlist */
  const { data: userWishlistData } = useQuery({
    queryKey: ['user', 'wishlist'],
    queryFn: () => getRequest(ALL_WISHLIST_ITEMS_URL),
    enabled: isAuth,
  });

  useEffect(() => {
    if (userWishlistData) setWatchList(userWishlistData);
  }, [userWishlistData]);

  useEffect(() => {
    if (width <= 600) {
      setShowBottomNav(true);
    } else {
      setShowBottomNav(false);
    }
  }, [width]);

  useEffect(() => {
    const data = remoteConfig.getAll();
    for (let x in data) {
      data[x] = data[x].asString();
    }
    // console.log(data);
    setRemoteConfigData(data);
  }, [isRemoteConfigDataFetched]);

  //useEffect
  useEffect(() => {
    setNavTab(0);
  }, [isAuth]);

  useEffect(() => {
    const shrinkOnScroll = () => {
      const nav = document.getElementById('header');
      if (!nav) return;
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        nav.style.padding = '0 1rem';
      } else {
        nav.style.padding = '1rem 1rem';
      }
    };
    window.addEventListener('scroll', shrinkOnScroll);
    return () => {
      window.removeEventListener('scroll', shrinkOnScroll);
    };
  }, []);

  // for main app
  return (
    <Router>
      <CssBaseline />
      <ScrollToTop />
      <Routes />
      <Login />
      {width < 600 && showBottomNav && <BottomNav />}
    </Router>
  );
};

export default App;
