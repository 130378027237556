import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import toast from '../Toast/toast';
import useMiscStore from '../../Store/MiscStore';
import { postRequest } from '../../api_calls';
import { PASSWORD_UPDATE_URL, USER_CHECK_EXISTING_URL } from '../../api_config';
import './Signup.css';
import { auth, firebase } from '../../Authentication/firebase';

/*  Forgot Password Form */
function ForgotPasswordView() {
  /* zustand vars */
  const { setFormView } = useMiscStore();
  /* zustand vars */

  // for storing updated values
  const [values, setValues] = useState({
    updatedPassword: '',
    phoneNumber: '',
  });
  // otp valid boolean
  const [valid, setValid] = useState(false);
  // otp invalid text boolean
  const [error, setError] = useState(false);
  // invalid otp error text
  const [errorMessage, setErrorMessage] = useState('');
  // password validation error text
  const [errMsg, setErrMsg] = useState('');
  // unmatched new password and confirm password error text
  const [errorMsg, setErrorMsg] = useState('');
  // user existing boolean
  const [isExisting, setIsExisting] = useState('');
  // otp value
  const [otp, setOtp] = useState();
  // show otp page
  const [show, setshow] = useState(false);
  // firebase otp validation boolean
  const [final, setfinal] = useState('');
  // new and confirm password values
  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const { mutate: checkExistingUser } = useMutation({
    mutationFn: data => postRequest(USER_CHECK_EXISTING_URL, data),
    onSuccess: data => {
      if (data) {
        setIsExisting('');
        signin();
      } else {
        setIsExisting('User does not exists');
      }
    },
  });

  const { mutate: updatedPassword } = useMutation({
    mutationFn: data => postRequest(PASSWORD_UPDATE_URL, data),
    onSuccess: () => {
      setFormView(0);
      toast.success('Your account password has been changed successfully');
    },
  });

  /* validation to match new and confirm password */
  function validatePasswords() {
    const compare = passwords.newPassword.localeCompare(
      passwords.confirmPassword,
    );
    if (compare === 0) {
      setValues({ ...values, updatedPassword: passwords.confirmPassword });
      setErrorMsg('');
    } else {
      setErrorMsg('Passwords do not match');
    }
  }
  /* validation to match new and confirm password */

  /* password length validation */
  const validatePass = () => {
    if (!passwords.newPassword) {
      setErrMsg('Please enter new password');
    } else if (passwords.newPassword.length < 5) {
      setErrMsg('Password must be more than 5 characters');
    } else {
      setErrMsg('');
    }
  };
  /* password length validation */

  useEffect(() => {
    validatePass();
    validatePasswords();
  }, [passwords.newPassword, passwords.confirmPassword]);

  // controlling values state
  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // controlling passwords state
  const handlePasswordChange = e => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  /* verification for user existing in the db */
  const handleCheckUserExistsRequest = () => {
    const data = {
      phoneNumber: values.phoneNumber,
    };
    checkExistingUser(data);
  };
  /* verification for user existing in the db */

  /* updating passwords */
  const handleChangePassword = () => {
    validatePass();
    if (errMsg !== '') return;
    setValues({ ...values, updatedPassword: passwords.confirmPassword });
    updatedPassword({
      updatedPassword: values.updatedPassword,
      phoneNumber: values.phoneNumber,
    });
  };
  /* updating passwords */

  /* recaptcha verification */
  const signin = () => {
    if (valid) return;
    let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    auth
      .signInWithPhoneNumber('+91' + values.phoneNumber, verify)
      .then(result => {
        setfinal(result);
        setshow(true);
        setValid(false);
      })
      .catch(err => {
        alert(err);
        window.location.reload();
      });
  };
  /* recaptcha verification */

  /* otp validation */
  const ValidateOtp = () => {
    if (otp === '' || final === null) {
      setError(true);
      setErrorMessage('Invalid OTP');
      return;
    }
    setError(false);
    setErrorMessage('');
    final
      .confirm(otp)
      .then(result => {
        setValid(true);
        setshow(false);
      })
      .catch(err => {
        setError(true);
        setErrorMessage('Invalid OTP');
      });
  };
  /* otp validation */

  return (
    <>
      <div className="pop-title">Reset Password</div>
      {!valid ? (
        <>
          <div style={{ display: !show ? 'block' : 'none' }}>
            <br />
            <TextField
              label="Mobile Number"
              onChange={handleChange}
              sx={{ width: '100%', my: '2%' }}
              type="tel"
              value={values.phoneNumber}
              name="phoneNumber"
            />
            <br />
            <div id="recaptcha-container"></div>

            {error && <p className="error">{errorMessage}</p>}
            {isExisting !== '' && <p className="error">{isExisting}</p>}
            <Button
              variant="contained"
              sx={{ my: '2%', mx: 'auto' }}
              onClick={handleCheckUserExistsRequest}
            >
              Send OTP
            </Button>
          </div>
          <div style={{ display: show ? 'block' : 'none' }}>
            <TextField
              type="text"
              placeholder={'Enter your OTP'}
              sx={{ width: '100%', my: '2%' }}
              onChange={e => {
                setOtp(e.target.value);
              }}
            />
            <br />
            <Button
              variant="contained"
              sx={{ my: '2%', mx: 'auto' }}
              onClick={ValidateOtp}
            >
              Verify
            </Button>
            {error && <p className="error">{errorMessage}</p>}
          </div>
        </>
      ) : (
        <>
          <TextField
            style={{ marginTop: '10px' }}
            label="New Password"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
            type="password"
            name="newPassword"
          />
          {passwords.newPassword.length > 0 && errMsg !== '' && (
            <p className="error">{errMsg}</p>
          )}
          <TextField
            style={{ marginTop: '10px' }}
            label="Confirm Password"
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            type="text"
            name="confirmPassword"
          />
          {passwords.confirmPassword.length > 0 && errorMsg !== '' && (
            <p className="error">{errorMsg}</p>
          )}
          <Button
            variant="contained"
            sx={{ my: '2%', mx: 'auto' }}
            onClick={handleChangePassword}
          >
            Change Password
          </Button>
        </>
      )}
    </>
  );
}

export default ForgotPasswordView;
