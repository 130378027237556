import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useBottomNavStore from '../../Store/BottomNavStore';
import useMiscStore from '../../Store/MiscStore';
import useProductStore from '../../Store/ProductStore';
import LeftDrawer from './LeftDrawer';
import SearchBox from './SearchBox';
import logo from './rer-logo/logo.png';
import RerImage from '../RerImage/RerImage';
// HeaderMobileView
export default function HeaderMobileView({ searchVisible = false }) {
  /*useState*/
  const [searchbar, setSearchBar] = useState(false);
  const { openLeftDrawer, setOpenLeftDrawer } = useMiscStore();

  /*Zustand variables*/
  const { search, setSearch } = useProductStore();
  const { setNavTab } = useBottomNavStore();

  useEffect(() => {
    setOpenLeftDrawer(false);
    return () => {
      setOpenLeftDrawer(false);
    };
  }, []);

  return (
    <>
      <Box sx={{ boxShadow: 'none' }}>
        <AppBar position="fixed" sx={{ bgcolor: '#fff' }}>
          <Toolbar
            sx={{
              p: 0,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconButton onClick={() => setOpenLeftDrawer(!openLeftDrawer)}>
              <MenuIcon color="primary" sx={{ margin: '0.5rem' }} />
            </IconButton>
            <Link to="/" onClick={() => setNavTab(0)}>
              <div
                style={{
                  width: '6.5rem',
                  marginTop: '.5rem',
                }}
              >
                <RerImage
                  src={logo}
                  alt="home"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </div>
            </Link>
            {searchVisible ? (
              <IconButton onClick={() => setSearchBar(prev => !prev)}>
                <SearchIcon color="primary" sx={{ margin: '0.5em' }} />
              </IconButton>
            ) : (
              <div style={{ width: '4rem' }}></div>
            )}
          </Toolbar>
          <Collapse in={searchbar} sx={{ width: '100vw' }}>
            <SearchBox
              search={search}
              setSearch={setSearch}
              width="96%"
              padding=".5rem 0"
            />
          </Collapse>
        </AppBar>
      </Box>
      {openLeftDrawer && <LeftDrawer />}
    </>
  );
}
