import create from 'zustand';
import { persist } from 'zustand/middleware';

const usePaymentStore = create(
  persist(
    set => ({
      /* payment key from razorpay transaction */
      key: '',
      setKey: key => set({ key }),
      /* boolean for payment potal to open */
      showPayment: false,
      setShowPayment: input => set({ showPayment: input })
    }),
    {
      name: 'payment-data'
    }
  )
);

export default usePaymentStore;
