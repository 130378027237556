import create from 'zustand';
import { persist } from 'zustand/middleware';

const useRecipeStore = create(
  persist(
    set => ({
      /* recipe user login status */
      isLogin: false,
      setIsLogin: isLogin => set({ isLogin }),
      /* recipe language */
      language: 'eng',
      setLanguage: language => set({ language }),
      /* all recipe users list */
      allRecipeUsers: [],
      setAllRecipeUsers: allRecipeUsers => set({ allRecipeUsers }),
      /* recipe user data */
      recipeUser: {},
      setRecipeUser: recipeUser => set({ recipeUser }),
      /* all recipes array */
      allRecipes: [],
      setAllRecipes: allRecipes => set({ allRecipes }),
      /* user created recipes */
      myRecipes: [],
      setMyRecipes: myRecipes => set({ myRecipes }),
      /* recipe form constants */
      recipeFormConstants: {},
      setRecipeFormConstants: recipeFormConstants =>
        set({ recipeFormConstants }),
      /* boolean to set path invalid */
      isValidPath: false,
      setIsValidPath: isValidPath => set({ isValidPath }),
    }),
    // key for localstorage item
    { name: 'recipes-data' },
  ),
);

export default useRecipeStore;
