import create from 'zustand';
import { persist } from 'zustand/middleware';

const useCartStore = create(
  persist(
    (set, get) => ({
      /* cart array */
      cart: [],
      /* for finding cart item by id */
      findById: productId => {
        return get().cart?.find(cartItem => cartItem.productId == productId);
      },
      findByUUID: uuid => {
        return get().cart?.find(cartItem => cartItem.uuid == uuid);
      },
      findByUrlName: urlName => {
        return get().cart?.find(cartItem => cartItem.urlName === urlName);
      },
      /* modifying cart array */
      setCart: cart => {
        set({ cart });
      },
      /* adding single item cart */
      addToCart: cartItem => {
        set({ cart: [...get().cart, cartItem] });
      },
      /* remove single item from cart */
      removeFromCart: (productId, price, qty) => {
        set({
          cart: get().cart?.filter(
            cartItem => cartItem.productId !== productId,
          ),
        });
        /*set({ subTotal: (get().subTotal -= price * qty) });*/
      },
      /* increase item quantity by one */
      increaseQuantity: (productId, amount) => {
        set({
          cart: get().cart?.map(cartItem =>
            cartItem.productId === productId
              ? { ...cartItem, qty: cartItem.qty + 1 }
              : cartItem,
          ),
        });
      },
      /* decrease item quantity by one */
      decreaseQuantity: (productId, amount) => {
        set({
          cart: get().cart?.map(cartItem =>
            cartItem.productId === productId
              ? { ...cartItem, qty: cartItem.qty - 1 }
              : cartItem,
          ),
        });
        set({
          cart: get().cart?.filter(cartItem => cartItem.qty !== 0),
        });
      },
      updateEachItemAmount: (productId, amount) => {
        set({
          cart: get().cart.map(cartItem =>
            cartItem.productId === productId
              ? { ...cartItem, amount: amount }
              : cartItem,
          ),
        });
      },
      appliedCouponCode: '',
      setAppliedCouponCode: appliedCouponCode => set({ appliedCouponCode }),
      promocashDiscount: 0,
      setPromocashDiscount: promocashDiscount => set({ promocashDiscount }),
      couponResponse: {},
      setCouponResponse: couponResponse => set({ couponResponse }),
      calculateFixedCouponDiscount: 0,
      setCalculateFixedCouponDiscount: calculateFixedCouponDiscount =>
        set({ calculateFixedCouponDiscount }),
      calculatePercentCouponDiscount: 0,
      setCalculatePercentCouponDiscount: calculatePercentCouponDiscount =>
        set({ calculatePercentCouponDiscount }),
    }),
    /* key for local storage item */
    {
      name: 'cart-mgmt',
    },
  ),
);

export default useCartStore;
