import create from 'zustand';
import { persist } from 'zustand/middleware';

const useCheckoutStore = create(
  persist(
    set => ({
      /* array of user saved addresses */
      details: [],
      setDetails: async d => set({ details: await d }),
      /* order address id */
      orderAddressId: null,
      setOrderAddressId: input => set(state => ({ orderAddressId: input }))
    }),
    {
      name: 'address-store'
    }
  )
);

export default useCheckoutStore;
