import { axiosInstance } from './axios_assets';

async function makeRequest(url, method = 'GET', options = {}, callback = null) {
  try {
    const response = await axiosInstance({
      method,
      url,
      ...options,
    });

    // If a callback function is provided, call it with the response object
    if (callback && typeof callback === 'function') {
      callback(response);
    }

    return response.data; // Return response.data directly
  } catch (error) {
    console.error(error, error.config.url);
    // Re-throw the error for React Query to handle
    throw error;
  }
}

export async function getRequest(url, config = {}, callback = null) {
  return makeRequest(url, 'GET', config, callback);
}

export async function postRequest(
  url,
  data = null,
  config = {},
  callback = null,
) {
  return makeRequest(url, 'POST', { data, ...config }, callback);
}

export async function putRequest(
  url,
  data = null,
  config = {},
  callback = null,
) {
  return makeRequest(url, 'PUT', { data, ...config }, callback);
}

export async function deleteRequest(url, config = {}, callback = null) {
  return makeRequest(url, 'DELETE', config, callback);
}
